import React, { Component } from 'react';

export class Home extends Component {
    render() {
        return (
            <div className='page'  >
                
                <a >
                    <span>
                        <a className='title'>Home</a> 
                    </span>
                   
               </a>
               <hr/>
               
               
                       
            </div>
        )
    }

}
export default Home;